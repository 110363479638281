import React from "react";
import { FaArrowRight } from "react-icons/fa";

import { GoCheckCircleFill } from "react-icons/go";
export default function SuccessPayment() {
  return (
    <div className="flex w-full  items-center h-[100vh] justify-center">
      <div className="flex flex-col  gap-6 items-center sm:p-8 p-4 rounded-md shadow-lg justify-center">
        <img src="/logo.png" alt="success" className=" w-1/2" />
        <GoCheckCircleFill color="green" size={35} />
        <h1 className="sm:text-2xl text-lg font-bold text-green-500">
          Payment successful
        </h1>
        <p className="sm:text-lg text-base">Thank you for choosing MoneyPal.</p>
        <a
          href="/home"
          className="bg-green-500 flex items-center gap-3 text-white sm:text-lg text-sm hover:bg-green-700 px-6 py-2 rounded-md cursor-pointer"
        >
          Go to home <FaArrowRight />
        </a>
      </div>
    </div>
  );
}
