import toast from "react-hot-toast";

export default async function sendResetPasswordMail(email) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API}send-reset-password-email`,
      {
        method: "POST",
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const json = await res.json();

    if (!res.ok) {
      return toast.error(json.message || "Something went wrong");
    }

    return toast.success(
      json?.message || "Reset password link sent successfully"
    );
  } catch (err) {
    return { error: err.message };
  }
}
