import React from "react";
import RegisterForm from "../../components/register-form";

const RegisterPage = () => {
  return (
    <div className=" relative login-page flex w-full items-center justify-center   h-[100vh]  ">
      <div className="md:w-[50vw] lg:w-[32vw] sm:w-[50vw] w-[90%] shadow-lg border border-slate-500 space-y-4  rounded-3xl p-8">
        <div className="flex w-full items-center justify-center">
          <img
            src="/logo.png"
            alt="spotify"
            className="sm:w-64 sm:h-36 h-full w-[75%]"
          />
        </div>
        <p className="sm:text-4xl text-2xl font-semibold text-center">
          Register
        </p>
        <br />

        <RegisterForm />
        <br />
        <div className="flex flex-col  justify-center items-center gap-1">
          <h1 className="sm:text-xl text-sm font-semibold">Already have an account?</h1>
          <a
            className="cursor-pointer sm:text-lg text-xs text-violet-500 font-semibold"
            href="/"
          >
            Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
