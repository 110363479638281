import React from "react";

export default function PrivacyPage() {
  return (
    <div className="layout px-4 bg-white text-black py-10 min-h-[100vh]">
      <h1 className="text-xl font-semibold">Privacy Policy</h1>
      <br />
      <ul>
        <li>
          At Game Vault, we prioritize the privacy and security of our users.
        </li>
        <li>
          We collect minimal personal information, which is used solely for
          enhancing the gaming experience and ensuring smooth platform
          operations.
        </li>
        <li>
          We do not share your data with third parties without your explicit
          consent.
        </li>
        <li>
          All user data is stored securely and protected with advanced
          encryption methods.
        </li>
        <li>
          By using Game Vault, you agree to our privacy practices as outlined.
        </li>
      </ul>
    </div>
  );
}
