import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { CiMail } from "react-icons/ci";
import { FaEye, FaEyeSlash, FaSpinner } from "react-icons/fa";
import { MdError } from "react-icons/md";
import * as yup from "yup";
import loginUser from "../services/login";
import ResetPasswordModal from "./ui/reset-password-modal";

const schema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup
    .string()
    .min(8)
    .required("Password is required and should be 8 characters long"),
});

export default function LoginForm() {
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isPassword, setIsPassword] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "all",
  });

  const onSubmit = async (data) => {
    setLoading(true);
    await loginUser(data);

    setLoading(false);
  };

  function closeModal() {
    setModalOpen(false);
  }
  return (
    <div className="w-full text-white">
      <form
        className="flex flex-col items-center gap-4 w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="relative w-full sm:text-2xl text-lg">
          {!errors.email ? (
            <CiMail className=" text-gray-700 absolute sm:top-4 right-4 top-3 " />
          ) : (
            <MdError
              className=" text-gray-700 absolute sm:top-4 right-4 top-3 "
              color={"red"}
            />
          )}
          <input
            {...(register && {
              ...register("email"),
            })}
            type="email"
            placeholder="Enter email"
            className={`flex  px-4 sm:text-lg text-sm sm:py-4 py-3 bg-white text-black placeholder:text-black border-1 w-full  rounded-2xl  transition-all cursor-pointer duration-300 ${
              errors.email ? "border-red-500 border" : "border-gray-300"
            }`}
          />

          {errors.email && (
            <p className="text-red-500 sm:text-base text-xs text-start first-letter:capitalize">
              {errors.email.message}
            </p>
          )}
        </div>
        <div className="relative w-full sm:text-2xl text-lg">
          {!errors.password ? (
            isPassword ? (
              <FaEyeSlash
                className=" text-gray-700 cursor-pointer absolute sm:top-4 right-4 top-3 "
                onClick={() => setIsPassword((prev) => !prev)}
              />
            ) : (
              <FaEye
                className=" text-gray-700 cursor-pointer absolute sm:top-4 right-4 top-3 "
                onClick={() => setIsPassword((prev) => !prev)}
              />
            )
          ) : (
            <MdError
              className=" text-gray-700 absolute sm:top-4 right-4 top-3 "
              color={"red"}
            />
          )}

          <input
            type={!isPassword ? "text" : "password"}
            {...(register && {
              ...register("password"),
            })}
            placeholder="Enter password"
            className={`flex px-4 sm:text-lg text-sm sm:py-4 py-3 bg-white text-black placeholder:text-black border-1 w-full  rounded-2xl  transition-all cursor-pointer duration-300 ${
              errors.password ? "border-red-500 border" : "border-gray-300"
            }`}
          />
          {errors.password && (
            <p className="text-red-500 sm:text-base text-xs text-start first-letter:capitalize ">
              {errors.password.message}
            </p>
          )}

          <span
            onClick={() => setModalOpen(true)}
            className="flex w-full pt-2 cursor-pointer hover:underline text-sm justify-end"
          >
            Forgot password?{" "}
          </span>
        </div>

        <button
          className="bg-black flex gap-4 items-center font-bold justify-center sm:text-xl text-lg cursor-pointer w-full text-white  sm:py-4 py-2 rounded-lg"
          type="submit"
        >
          Login
          {loading && <FaSpinner className="animate-spin" />}
        </button>
      </form>
      <br />
      <hr />
      <ResetPasswordModal isOpen={modalOpen} close={closeModal} />
    </div>
  );
}
