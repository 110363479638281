import React from "react";
import { FaSpinner } from "react-icons/fa";

export default function DeleteAccPage() {
  const loading = false;
  return (
    <div className="layout h-[100vh] flex items-center justify-center">
      <div className="flex flex-col items-center gap-4 sm:w-1/2 lg:w-1/3 w-[95%] shadow-lg border border-slate-500 p-8 rounded-3xl">
        <div className="relative w-full sm:text-2xl text-lg">
          <input
            type="email"
            placeholder="Enter email"
            className={`flex  px-4 sm:text-lg text-sm sm:py-4 py-3 bg-white text-black placeholder:text-black border-1 w-full  rounded-2xl  transition-all cursor-pointer duration-300 `}
          />
        </div>
        <div className="relative w-full sm:text-2xl text-lg">
          <input
            type={"password"}
            placeholder="Enter password"
            className={`flex px-4 sm:text-lg text-sm sm:py-4 py-3 bg-white text-black placeholder:text-black border-1 w-full  rounded-2xl  transition-all cursor-pointer duration-300`}
          />
        </div>

        <button
          className="bg-red-700 flex gap-4 items-center font-bold justify-center sm:text-xl text-lg cursor-pointer w-full text-white  sm:py-4 py-2 rounded-lg"
          type="submit"
        >
          Delete Account
          {loading && <FaSpinner className="animate-spin" />}
        </button>
      </div>
    </div>
  );
}
