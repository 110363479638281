import toast from "react-hot-toast";

export default async function changePassword({ token, password }) {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_API}reset-password?token=${token}&password=${password}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const json = await res.json();

    if (!res.ok) {
      return toast.error(json.message || "Something went wrong");
    }
    return toast.success(" Password updated successfully");
  } catch (err) {
    return { error: err.message };
  }
}
