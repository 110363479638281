// import { TRegisterForm } from "#/components/login/register-form";
import { toast } from "react-hot-toast";
import { TOKEN_KEY, USER_KEY } from "../utils/constants";

export default async function loginUser(data) {
  try {
    const res = await fetch(`${process.env.REACT_APP_API}user/login`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const json = await res.json();

    if (!res.ok) {
      return toast.error(json.message);
    }

    localStorage.setItem(TOKEN_KEY, json.token);
    localStorage.setItem(USER_KEY, JSON.stringify(json.data));

    document.cookie = `token=${json.token}`;

    window.location.href = "/home";

    toast.success("Logged in successfully");
  } catch (err) {
    toast.error(err.message);
  }
}
