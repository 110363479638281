import React, { useState } from "react";
import { FaEye, FaEyeSlash, FaSpinner } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import changePassword from "../../services/change-password";
import toast from "react-hot-toast";

export default function ResetPasswordForm() {
  const [loading, setLoading] = useState(false);
  const [isPassword, setIsPassword] = useState(true);
  const [password, setPassword] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const onSubmit = async () => {
    setLoading(true);
    if (!password) {
      setLoading(false);
      return toast.error("Password is required");
    }
    if (password.trim().length < 8) {
      setLoading(false);
      return toast.error("Password should be atleast 8 characters long");
    }
    await changePassword({
      password: password,
      token: token,
    });

    setLoading(false);
    window.location.href = "/";
  };

  return (
    <div className="w-full text-white">
      <div className="flex flex-col items-center gap-4 w-full">
        <div className="relative w-full sm:text-2xl text-lg">
          {isPassword ? (
            <FaEyeSlash
              className=" text-gray-700 cursor-pointer absolute sm:top-4 right-4 top-3 "
              onClick={() => setIsPassword((prev) => !prev)}
            />
          ) : (
            <FaEye
              className=" text-gray-700 cursor-pointer absolute sm:top-4 right-4 top-3 "
              onClick={() => setIsPassword((prev) => !prev)}
            />
          )}
          <input
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
            type={!isPassword ? "text" : "password"}
            placeholder="Enter new password"
            className={`flex px-4 sm:text-lg text-sm sm:py-4 py-3 bg-white text-black placeholder:text-black border-1 w-full  rounded-2xl  transition-all cursor-pointer duration-300 border-gray-300`}
          />
        </div>

        <button
          onClick={onSubmit}
          className="bg-black flex gap-4 items-center font-bold justify-center sm:text-xl text-lg cursor-pointer w-full text-white  sm:py-4 py-2 rounded-lg"
        >
          Change Password
          {loading && <FaSpinner className="animate-spin" />}
        </button>
      </div>
      <br />
    </div>
  );
}
