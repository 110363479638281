import React from "react";
import { Toaster } from "react-hot-toast";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
// import "../src/App.css";
import MainPage from "./layout/landing-page";
import LoginPage from "./layout/login";
import SuccessPayment from "./layout/payment-succes";
import RegisterPage from "./layout/register";
import ResetPassword from "./layout/reset-password";
import DeleteAccPage from "./layout/delete_acc";
import PrivacyPage from "./layout/privacy";

function App() {
  return (
    <main className="App bg-[#16151a] text-white">
      <Toaster
        position="top-right"
        toastOptions={{
          style: {
            zIndex: 999999999999999,
          },
        }}
      />
      {/* <Navbar /> */}
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/delete_acc" element={<DeleteAccPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/home" element={<MainPage />} />
          <Route path="/success" element={<SuccessPayment />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </Router>
    </main>
  );
}

export default App;
