import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaSpinner, FaUserCheck } from "react-icons/fa";
import { useSelector } from "react-redux";
import { IoLogOutOutline } from "react-icons/io5";
import { TOKEN_KEY, USER_KEY } from "../../utils/constants";

const stripePromise = loadStripe(process.env.REACT_APP_KEY);

const MainPage = () => {
  const user = useSelector((state) => state.user);

  // Simulated cart data, replace or adjust based on actual application data
  const [carts, setCarts] = useState([
    {
      id: 1,
      dish: "Make payment",
      price: 10,
      qnty: 1,
      imgdata: "link_to_image",
    },
  ]);

  const [amount, setAmount] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleCheckout = async () => {
    setIsProcessing(true);
    const stripe = await stripePromise;

    // Update the cart with the entered amount
    const updatedCarts = carts.map((cartItem) => ({
      ...cartItem,
      price: parseFloat(amount),
      description: user?.user?.code || "No code",
    }));

    const body = {
      products: updatedCarts,
    };

    const headers = {
      "Content-Type": "application/json",
    };

    // Post request to server to create a checkout session
    const response = await fetch(
      `${process.env.REACT_APP_API}create-checkout-session`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(body),
      }
    );

    const session = await response.json();

    // Redirect to Stripe Checkout
    const result = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (result.error) {
      alert(result.error.message);
      setIsProcessing(false);
      toast.error("An error occurred, please try again later.");
    }
    setIsProcessing(false);
  };

  useEffect(() => {
    if (user.user === null) {
      window.location.replace("/");
    }
  }, [user.user]);

  const handleLogout = () => {
    window.location.replace("/");
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_KEY);
  };

  return (
    <main className="gap-6 text-white flex-col h-[100vh] overflow-hidden items-center justify-start flex">
      <div className="flex sm:px-28 py-4 px-5 shadow-lg flex-row items-center sm:justify-end justify-between w-full">
        <div className="flex items-center sm:text-lg text-sm gap-3">
          <button
            onClick={handleLogout}
            className="sm:py-2 py-2 sm:px-4 px-3 flex flex-row items-center gap-2 rounded-lg hover:bg-green-800 bg-green-700 cursor-pointer"
          >
            Logout
            <IoLogOutOutline size={25} />
          </button>
          <span className="flex items-center sm:gap-3 gap-2 py-2 sm:px-5 px-3 rounded-xl border border-slate-500">
            <FaUserCheck />
            {user?.user?.email || "Not logged in"}
          </span>
        </div>
      </div>
      <div className="flex  h-full flex-col gap-5  items-center justify-center">
        <div className="flex border flex-col  gap-6  border-slate-500 px-10 py-6 rounded-3xl items-center sm:mx-0 mx-5">
          <img src="/logo.png" className="w-32 " alt="logo" />
          <h1 className="sm:text-2xl text-lg  font-semibold">Hi, Welcome</h1>
          <p className="whitespace-nowrap sm:text-lg text-sm">
            Enter amount you want to
          </p>
          <div className="flex flex-col items-center gap-3 text-xl">
            <input
              type="number"
              value={amount}
              className={`flex px-4 sm:text-lg text-sm sm:py-4 py-3 bg-white text-black placeholder:text-black border-1 w-full  rounded-2xl  transition-all cursor-pointer duration-300 ${
                amount <= 0 ? "border-red-500 border" : "border-gray-300"
              }`}
              onChange={(e) => setAmount(e.target.value)}
            />
            {amount <= 0 && (
              <p className="text-red-500 sm:text-base text-xs text-start first-letter:capitalize ">
                {"Please enter a valid amount."}
              </p>
            )}
          </div>
          <button
            className="bg-black justify-center items-center flex cursor-pointer font-semibold transition-all duration-300 text-white w-full gap-4 sm:px-8 px-4 sm:text-lg text-sm sm:py-3 py-2 rounded-md"
            onClick={handleCheckout}
            disabled={
              amount < 1 ||
              isProcessing ||
              user.user === null ||
              user.user.email === null
            }
          >
            Proceed to Payment
            {isProcessing && <FaSpinner className="animate-spin" />}
          </button>
        </div>
      </div>
    </main>
  );
};

export default MainPage;
