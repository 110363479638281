import React from "react";
import ResetPasswordForm from "./form";
import { FaArrowLeft } from "react-icons/fa";

export default function ResetPassword() {
  return (
    <div className=" relative login-page flex w-full items-center justify-center   h-[100vh]  ">
      <div className="md:w-[50vw] lg:w-[30vw] sm:w-[50vw] w-[90%] shadow-lg border border-slate-500 space-y-4  rounded-3xl p-8">
        <p>
          Change your password here. Please enter your new password and confirm
        </p>
        <br />

        <ResetPasswordForm />
        <br />
      </div>
      <a
        href="/"
        className=" flex items-center gap-2 absolute text-white top-24 left-24 border py-2 px-4 cursor-pointer rounded-md text-lg font-bold"
      >
        <FaArrowLeft />
        Login
      </a>
    </div>
  );
}
