import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import sendResetPasswordMail from "../../services/send-reset-password-mail";
import toast from "react-hot-toast";
import { FaSpinner } from "react-icons/fa";

export default function ResetPasswordModal({ isOpen, close }) {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSendEmail = async () => {
    setLoading(true);
    if (!email) {
      setLoading(false);
      return toast.error("Email is required");
    }

    // check if email is valid
    if (!email.includes("@") || !email.includes(".")) {
      setLoading(false);
      return toast.error("Invalid email");
    }
    await sendResetPasswordMail(email);
    setLoading(false);
    close();
  };

  const [countdown, setCountdown] = useState(30);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCount) => prevCount - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countdown]);
  return (
    <div>
      <div>
        <Transition appear show={isOpen}>
          <Dialog
            as="div"
            className="relative z-10 focus:outline-none"
            onClose={close}
          >
            <div className="fixed inset-0 z-10 bg-black/75 w-screen overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4">
                <TransitionChild
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 transform-[scale(95%)]"
                  enterTo="opacity-100 transform-[scale(100%)]"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 transform-[scale(100%)]"
                  leaveTo="opacity-0 transform-[scale(95%)]"
                >
                  <DialogPanel className="w-full max-w-md rounded-xl bg-white/5 p-6 backdrop-blur-2xl">
                    <DialogTitle
                      as="h3"
                      className=" sm:text-lg text-base font-medium text-white"
                    >
                      Enter the email you want to reset the password.
                    </DialogTitle>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      placeholder="Enter email"
                      className="flex border mt-2 px-4 sm:text-base text-sm sm:py-2 py-3 text-white placeholder:text-white border-1 w-full  rounded-md bg-transparent  transition-all cursor-pointer duration-300"
                    />
                    <div className="my-4">
                      <Button
                        className="inline-flex items-center gap-2 rounded-md bg-gray-700 py-1.5 px-3 text-sm/6 font-semibold text-white shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-gray-600 data-[open]:bg-gray-700 data-[focus]:outline-1 data-[focus]:outline-white"
                        onClick={handleSendEmail}
                      >
                        Send Email{" "}
                        {loading && <FaSpinner className="animate-spin" />}
                      </Button>
                    </div>
                    <hr />

                    <div className="flex mt-4 text-white items-center justify-end gap-4 space-y-2">
                      <p>
                        Didn&apos;t receive the mail?
                        <Button
                          onClick={handleSendEmail}
                          disabled={countdown > 0}
                          className="px-4 py-1  gap-3 ml-2 underline rounded-md bg-slate-600 w-fit text-white"
                        >
                          Resend Mail {countdown > 0 && "in" + countdown + "s"}{" "}
                          {loading && <FaSpinner className="animate-spin" />}
                        </Button>
                      </p>
                    </div>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
}

ResetPasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
